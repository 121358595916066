.container {
  border-radius: 80px;
  padding: 12px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: white;
}

.text {
  color: #29ABE2;
  font-size: 16px;
  padding: 0;
  margin: 0;
}
