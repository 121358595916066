.container {
  position: relative;
}

.container:hover .menuContainer {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.menuContainer {
  position: absolute;
  top: 100%;
  right: -16px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 220px;
  max-height: 160px;
  z-index: 300;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.9);
  transition: all 50ms;
}

.itemContainer {
  cursor: pointer;
}

.itemContainer:hover {
  background-color: #eee;
}
