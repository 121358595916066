.container {
  border-radius: 8px;
  border: 1px solid #007acc;
  padding: 0 16px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.text {
  color: #007acc;
  font-size: 18px;
  padding: 0;
  margin: 0;
}

