.container {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  background: transparent;
  border: none;
}


.container:hover {
  background: #29abe210;
}

.text {
  color: #29ABE2;
  font-size: 16px;
  padding: 0;
  margin: 0;
}
