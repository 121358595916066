.base {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.fullWidth {
  width: 100%;
}

.page {
  padding: 32px;
  height: 100vh;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
.row {
  flex-direction: row;
}

.fitParent {
  height: 100%;
  width: 100%;
}

.bottomBorder {
  border-bottom: 1px solid #DFEAF1;
}

.border {
  border-radius: 16px;
}

.card {
  background: #F6FBFE;
  border-radius: 16px;
}

.cardSecondary {
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  overflow: hidden;
}

.cardSecondaryNoOverflow {
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
}

.hoverEffect {
  transition: opacity 0.2s;
}

.hoverEffect:hover {
  opacity: 0.8;
}
