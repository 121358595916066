.button {
  outline: none;
  font-family: inherit;
  cursor: pointer;
  transition: opacity 0.2s;
  min-width: 120px;
  z-index: 2;
}

.button:hover {
  opacity: 0.8;
}

.withoutBorder {
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.disabled {
  opacity: 0.3;
  cursor: auto;
}

.disabled:hover {
  opacity: 0.3;
}
