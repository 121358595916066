.container {
  border-radius: 80px;
  padding: 12px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: linear-gradient(to left,#29ABE2, #0077FF);
}

.container svg {
  margin-right: 16px;
}

.text {
  color: white;
  font-size: 16px;
  padding: 0;
  margin: 0;
}
