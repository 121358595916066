.base {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.base:hover .tooltip {
  display: block;
}

.base.disabled:hover .tooltip {
  display: none;
}

.main {
  background: #f6fbfe;
}

.main:hover {
  background: #e7f2f8;
}

.home {
  background: linear-gradient(to left, #29abe2, #0077ff);
}

.homeRed {
  background: linear-gradient(270deg, rgba(250,103,103,1) 0%, rgba(255,27,27,1) 100%);
}

.home:hover,
.homeRed:hover {
  opacity: 0.8;
}

.normal {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.small {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.verySmall {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.normalWithText {
  height: 40px;
  border-radius: 20px;
}

.smallWithText {
  height: 32px;
  border-radius: 16px;
  padding: 0 8px;
}

.disabled {
  opacity: 0.3;
  cursor: default;
}

.home.disabled:hover,
.homeRed.disabled:hover {
  opacity: 0.3;
}

.main.disabled:hover {
  background: #f6fbfe;
}

.tooltip {
  position: absolute;
  top: 48px;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 100;
  border-radius: 8px;
  padding: 4px 8px;
  display: none;
  font-size: 12px;
  text-align: center;
}

.small .tooltip {
  transform: translateX(calc(-50% + 16px));
}

.normal .tooltip {
  transform: translateX(calc(-50% + 20px));
}

.text {
  white-space: nowrap;
  color: white;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 14px;
}

.link {
  text-decoration: none;
}
