.container {
    padding: 8px 8px;
    /* box-sizing: border-box; */
    width: 64px !important;
    min-width: 64px !important;
    display:inline-block;
    background: white;
    border: 0.5px solid #d2d2d2;
  }
  
  /* .container:hover {
    opacity: #ff00ae;
  } */

  .text {
    font-size: 14px;
    display: inline-block;
    padding: 0;
    margin: 0;
  }

.selected {
    background: #0278fd;
    color: white !important;
  }

.selected:hover {
    opacity: 0.8;
  }
  

  
  

  