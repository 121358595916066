.container {
  background: #007acc;
  border-radius: 8px;
  padding: 0 16px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  color: rgba(255, 255, 255, 0.87);
  font-size: 18px;
  padding: 0;
  margin: 0 16px;
}

