@keyframes load {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: #2c1229;
}

.logo {
  width: 160px;
  height: 160px;
  animation-name: load;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
