.container {
  background-image: linear-gradient(to top right, #29abe2, #0077ff);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerContainer {
  width: 80%;
  height: 100%;
  display: flex;
}

.featureContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1000px) {
  .featureContainer {
    display: none;
  }
}

@media (max-width: 600px) {
  .innerContainer {
    width: 100%;
  }
}

@media (max-height: 700px) {
  .container {
    height: auto;
  }

  .loginContainer {
    margin: 64px 0;
  }
}
