.container {
  position: relative;
}

.popupContainer {
  position: absolute;
  top: 100%;
  right: -32px;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.9);
  transition: all 50ms;
}

.container:hover .popupContainer {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.popupInnerContainer {
  background-color: white;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.25);
  width: 280px;
  border-radius: 8px;
}
