.container {
  border-radius: 80px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  background: transparent;
  border: none;
  width: 200px;
}

.selected {
  background: #29abe210;
}

.container:hover {
  background: #29abe210;
}

.container p {
  margin-left: 16px;
}

.text {
  color: black;
  font-size: 16px;
  padding: 0;
  margin: 0;
}
