.container {
  padding: 24px;
  background-color: white;
  border-radius: 16px;
}

.inputContainer {
  background-color: #f6fbfe;
  border-radius: 32px;
  display: flex;
  padding: 12px 16px;
  margin-top: 16px;
}

.inputContainer:focus-within {
  background-color: #ecf1f5;
}

.inputContainerInvalid {
  border: 1px solid red;
}

.input {
  margin-left: 8px;
  outline: none;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 16px;
}

.mainButton {
  width: 100%;
  margin-top: 24px;
}

.orContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.or {
  font-size: 14px;
  color: #6a8997;
  margin: 0 32px;
}

.divider {
  height: 2px;
  flex: 1;
  background: #e4eaee;
}

.termsOfUse {
  font-size: 12px;
  color: #707070;
  margin: 0;
  margin-top: 16px;
  text-align: center;
}

.termsOfUse span {
  color: #29abe2;
  text-decoration: underline;
  cursor: pointer;
}

.buttonsContainer {
  border-bottom: 2px solid #e4eaee;
  display: flex;
  justify-content: space-around;
}

.topButton {
  width: 80px;
  font-size: 18px;
  margin: 0;
  padding-bottom: 12px;
  cursor: pointer;
  border-bottom: none;
}

.topButtonSelected {
  color: #29abe2;
  border-bottom: 4px solid #29abe2;
}

.topButton:hover {
  opacity: 0.8;
}

.googleIcon {
  margin-right: 8px;
}
